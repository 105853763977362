<template>
    <div>
        <b-container class="mt-5 mb-5">
            <div class="mb-2 clearfix">
                <h1 class="fs-4 font-weight-bold mb-0">Bewerk ticket</h1>
            </div>
            <EventCanceledAlert :event="event" />
            <ValidationObserver ref="form" slim>
                <b-card>
                    <b-tabs content-class="p-3 tab-body">
                        <b-tab title="Algemeen" active>
                            <ValidationProvider rules="required" v-slot="{ errors }">
                                <div class="mb-3">
                                    <label class="required">Type ticket</label>
                                    <b-form-select v-model="form.type" :options="ticketTypeOptions" />
                                    <div v-if="errors.length" class="invalid-feedback">{{ errors[0] }}</div>
                                </div>
                            </ValidationProvider>

                            <ValidationProvider rules="required" v-slot="{ errors }">
                                <div class="mb-3">
                                    <label class="required">Naam ticket</label>
                                    <b-form-input v-model="form.name" />
                                    <div v-if="errors.length" class="invalid-feedback">{{ errors[0] }}</div>
                                </div>
                            </ValidationProvider>

                            <div class="mb-3">
                                <label>Beschrijving</label>
                                <b-form-textarea v-model="form.description" />
                            </div>

                            <div class="mb-3">
                                <label>Prijs</label>
                                <b-form-input v-money="money" v-model="form.price" min="0" placeholder="0.00" />
                            </div>

                            <div class="mb-3">
                                <label>Aantal beschikbare tickets</label>
                                <b-form-input v-model="form.amount_available" v-mask="'#########'" placeholder="Onbeperkt" min="0" />
                            </div>

                            <ValidationProvider rules="max:1000" v-slot="{ errors }">
                                <div class="mb-3">
                                    <label>Extra Informatie</label>
                                    <VueTrix v-model="form.extra_information" />
                                    <p class="text-muted mb-1">Bijvoorbeeld: Informatie over toegangsbeleid of overige toevoegingen.</p>
                                    <div v-if="errors.length" class="invalid-feedback">{{ errors[0] }}</div>
                                </div>
                            </ValidationProvider>
                        </b-tab>

                        <b-tab title="Afbeelding">
                            <div class="mb-3">
                                <label>Upload een afbeelding voor op je ticket</label>
                                <p class="text-muted">Optimaal formaat: 2200x2500px (max. 5MB)</p>
                                <DropFile v-model="form.header_image" accept="image/*" />
                            </div>
                        </b-tab>
                        <b-tab title="Verkoopdatum">
                            <div class="mb-3">
                                <label>Gelijk aan evenement</label>
                                <b-form-select v-model="form.is_custom_sell_date" :options="customSaleDateOptions" />
                            </div>
                            <template v-if="form.is_custom_sell_date">
                                <b-row>
                                    <b-col lg="6" class="mb-3">
                                        <label class="required">Startdatum</label>
                                        <b-row>
                                            <b-col cols="8">
                                                <ValidationProvider rules="required" v-slot="{ errors }">
                                                    <div class="mb-3">
                                                        <p class="text-muted mb-0">Datum</p>
                                                        <b-form-input v-model="form.start_sale_at_date" type="date" max="9999-12-31" />
                                                        <div v-if="errors.length" class="invalid-feedback">{{ errors[0] }}</div>
                                                    </div>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col cols="4">
                                                <ValidationProvider rules="required" v-slot="{ errors }">
                                                    <div class="mb-3">
                                                        <p class="text-muted mb-0">Tijd</p>
                                                        <b-form-input v-model="form.start_sale_at_time" type="time" />
                                                        <div v-if="errors.length" class="invalid-feedback">{{ errors[0] }}</div>
                                                    </div>
                                                </ValidationProvider>
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                    <b-col lg="6" class="mb-3">
                                        <label class="required">Einddatum</label>
                                        <b-row>
                                            <b-col cols="8">
                                                <ValidationProvider rules="required" v-slot="{ errors }">
                                                    <div class="mb-3">
                                                        <p class="text-muted mb-0">Datum</p>
                                                        <b-form-input v-model="form.end_sale_at_date" type="date" max="9999-12-31" />
                                                        <div v-if="errors.length" class="invalid-feedback">{{ errors[0] }}</div>
                                                    </div>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col cols="4">
                                                <ValidationProvider rules="required" v-slot="{ errors }">
                                                    <div class="mb-3">
                                                        <p class="text-muted mb-0">Tijd</p>
                                                        <b-form-input v-model="form.end_sale_at_time" type="time" />
                                                        <div v-if="errors.length" class="invalid-feedback">{{ errors[0] }}</div>
                                                    </div>
                                                </ValidationProvider>
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                </b-row>
                            </template>
                        </b-tab>
                        <b-tab title="Geldigheidsdatum">
                            <template v-if="form.type == 'multiple'">
                                <div class="mb-3">
                                    <label>Meerdaagse ticket</label>
                                    <p class="text-muted mb-0">Deze ticket mag elke dag gebruikt worden.</p>
                                    <b-form-select v-model="form.is_multiday_ticket" :options="yesNoOptions" />
                                </div>
                            </template>
                            <div class="mb-3">
                                <label>Gelijk aan evenement</label>
                                <b-form-select v-model="form.is_custom_valid_date" :options="customValidDateOptions" />
                            </div>
                            <template v-if="form.is_custom_valid_date">
                                <b-row>
                                    <b-col lg="6" class="mb-3">
                                        <label class="required">Startdatum</label>
                                        <b-row>
                                            <b-col cols="8">
                                                <ValidationProvider rules="required" v-slot="{ errors }">
                                                    <div class="mb-3">
                                                        <p class="text-muted mb-0">Datum</p>
                                                        <b-form-input v-model="form.start_valid_at_date" type="date" max="9999-12-31" />
                                                        <div v-if="errors.length" class="invalid-feedback">{{ errors[0] }}</div>
                                                    </div>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col cols="4">
                                                <ValidationProvider rules="required" v-slot="{ errors }">
                                                    <div class="mb-3">
                                                        <p class="text-muted mb-0">Tijd</p>
                                                        <b-form-input v-model="form.start_valid_at_time" type="time" />
                                                        <div v-if="errors.length" class="invalid-feedback">{{ errors[0] }}</div>
                                                    </div>
                                                </ValidationProvider>
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                    <b-col lg="6" class="mb-3">
                                        <label class="required">Einddatum</label>
                                        <b-row>
                                            <b-col cols="8">
                                                <ValidationProvider rules="required" v-slot="{ errors }">
                                                    <div class="mb-3">
                                                        <p class="text-muted mb-0">Datum</p>
                                                        <b-form-input v-model="form.end_valid_at_date" type="date" max="9999-12-31" />
                                                        <div v-if="errors.length" class="invalid-feedback">{{ errors[0] }}</div>
                                                    </div>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col cols="4">
                                                <ValidationProvider rules="required" v-slot="{ errors }">
                                                    <div class="mb-3">
                                                        <p class="text-muted mb-0">Tijd</p>
                                                        <b-form-input v-model="form.end_valid_at_time" type="time" />
                                                        <div v-if="errors.length" class="invalid-feedback">{{ errors[0] }}</div>
                                                    </div>
                                                </ValidationProvider>
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                </b-row>
                            </template>
                        </b-tab>
                    </b-tabs>
                    <template v-if="!event.canceled_at">
                        <div class="clearfix">
                            <b-button @click="removeTicket()" variant="danger">
                                Verwijderen
                                <font-awesome-icon icon="fa-solid fa-remove ml-2" />
                            </b-button>
                            <b-button @click="submitForm()" variant="primary" class="float-right">
                                Opslaan
                                <font-awesome-icon icon="fa-solid fa-chevron-right ml-2" />
                            </b-button>
                        </div>
                    </template>
                </b-card>
            </ValidationObserver>
        </b-container>
    </div>
</template>

<script>
import { VMoney } from 'v-money'
import VueTrix from "vue-trix";
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import DropFile from "@/components/DropFile";
import EventCanceledAlert from '@/components/event/EventCanceledAlert'

export default {
    metaInfo: {
        title: 'Tickets',
    },
    components: {
        VueTrix,
        EventCanceledAlert,
        ValidationObserver,
        ValidationProvider,
        DropFile,
    },
    directives: {
      money: VMoney
    },
    data() {
        return {
            money: {
              decimal: '.',
              thousands: '',
              prefix: '',
              suffix: '',
              precision: 2,
            },
            form: {},
            event: {},
            ticketTypeOptions: [
              { text: 'Selecteer een optie', value:null },
              { text: 'Toegangsticket', value:'ticket' },
              { text: 'Voucher', value:'voucher' },
            ],
            customValidDateOptions: [
              { text: 'Ja', value: false },
              { text: 'Nee', value: true }
            ],
            customSaleDateOptions: [
              { text: 'Ja', value: false },
              { text: 'Nee', value: true }
            ],
        }
    },
    computed: {},
    methods: {
        async submitForm() {
            let check = await this.$refs['form'].validate();

            if (!check) {
                window.scrollTo({
                    top: 0,
                    behavior: "smooth"
                });
                
                return;
            }

            this.$axios.put("https://api.tixgo.nl/eventstickets/" + this.$route.params.ticket, this.form)
                .then( response => {
                    response;
                    this.$router.push('/manage/events/' + this.$route.params.id + '/settings/tickets')
                })
            ;
        },
        removeTicket: function() {
            if( confirm("Weet je zeker dat je dit ticket wilt verwijderen?") ) {
                this.$axios.delete("https://api.tixgo.nl/eventstickets/" + this.$route.params.ticket)
                    .then( response => {
                        response;
                        this.$router.push('/manage/events/' + this.$route.params.id + '/settings/tickets')
                    })
                ;
            }
        },
        fetchTicket: function() {
            this.$axios.get("https://api.tixgo.nl/eventstickets/" + this.$route.params.ticket)
                .then( response => {
                    if (response.data) {
                        this.form = response.data;
                    }
                }
            ).catch((reason) => {
                console.log(reason);
            });
        },
        fetchEvent: function() {
            this.$axios.get("https://api.tixgo.nl/events/" + this.$route.params.id)
                .then( response => {
                    this.event = response.data;
                }
            ).catch((reason) => {
                console.log(reason);
            });
        },
    },
    created() {
        this.fetchTicket();
        this.fetchEvent();
    },
}
</script>